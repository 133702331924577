import React, { useReducer, useState } from 'react'
import { Box, Button, Card, CardContent, Container, Dialog, DialogContent, Divider, Grid, InputAdornment, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material'
import LOGO from '../assets/logo.png'
import { grey } from '@mui/material/colors'
import { LoadingButton} from '@mui/lab';



const Home = () => {
    const [open, setOpen] = useState(false)
    const [load, setLoad] = useState(false)
    const [monthly, setMonthly] = useState(0)
    const [loan, setLoan] = useState(0)
    const [duration, setDuration] = useState(null)

    const initState = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        salary: '',
        duration: ''
    }

    const loanReducer = (state, action) => {
        switch (action.type) {
            case 'FIRST':
                return {...state, firstname: action.payload}
            case 'LAST':
                return {...state, lastname: action.payload}
            case 'EMAIL':
                return {...state, email: action.payload}
            case 'PHONE':
                return {...state, phone: action.payload}
            case 'SALARY':
                return {...state, salary: action.payload}
            case 'DURATION':
                return {...state, duration: action.payload}
            case 'RESET':
                return initState
            default:
                return state;
        }
    }

    const [userInput, dispatch] = useReducer(loanReducer, initState)

    const onFormSubmit = () => {
        const amt = parseInt(userInput.salary)
        if(userInput.firstname === '' || userInput.lastname === '' || userInput.phone === '' || !userInput.email.includes('@') || userInput.salary === '' || userInput.duration === ''){
            alert('Sorry, all fields are required')
            return
        }
        setLoad(true)
        setOpen(true)
        setTimeout(() => {
            // check loan amount
            if(amt > 7000){
                const payment = 20000 / ( parseInt(userInput.duration))
                setLoan(20000)
                setDuration(userInput.duration)
                setMonthly(payment)
            }if(amt <= 7000 && amt >= 6000){
                const payment = 18000 / ( parseInt(userInput.duration))
                setLoan(18000)
                setDuration(userInput.duration)
                setMonthly(payment)
            }if(amt <= 6000 && amt >= 5000){
                const payment = 15000 / ( parseInt(userInput.duration))
                setLoan(15000)
                setDuration(userInput.duration)
                setMonthly(payment)
            }if(amt <= 5000 && amt >= 4000){
                const payment = 12000 / ( parseInt(userInput.duration))
                setLoan(12000)
                setDuration(userInput.duration)
                setMonthly(payment)
            }if(amt <= 4000 && amt >= 3000){
                const payment = 10000 / ( parseInt(userInput.duration))
                setLoan(10000)
                setDuration(userInput.duration)
                setMonthly(payment)
            }if(amt <= 3000 && amt >= 2000){
                const payment = 8000 / ( parseInt(userInput.duration))
                setLoan(8000)
                setDuration(userInput.duration)
                setMonthly(payment)
            }if(amt <= 2000 && amt >= 1000){
                const payment = 5000 / ( parseInt(userInput.duration))
                setLoan(5000)
                setDuration(userInput.duration)
                setMonthly(payment)
            }
            
            dispatch({type: 'RESET'})
            setLoad(false)
            setOpen(false)
        }, 3000);
    }
 
  return (
    <>
        {/* Navbar */}
        <Box sx={{padding: '10px 0', borderBottom: `1px solid ${grey[200]}`, bgcolor: '#fff'}}>
            <Container sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <img src={LOGO} width='15%' alt='logo' />
                <Button variant='contained' disableElevation color='secondary' href='https://wepaygh.com' sx={{textTransform: 'none'}}>Get A Loan Now</Button>
            </Container>
        </Box>

        <Box sx={{bgcolor: 'secondary.main', height: '50vh'}}>
            <Container sx={{height: '100%'}}>
                <Grid container sx={{height: '100%'}}>
                    <Grid item sm={12} sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography variant='h3' mb={2} sx={{fontWeight: 600, color: 'white'}}>Get up to GH¢ 20,000 <br />loan with WePayGh</Typography>
                    <Button variant='outlined' color='light' href='https://wepaygh.com/auth/register'>Register Today</Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>

        <Box my='5rem'>
            <Container>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} lg={7}>
                        <Card variant='outlined' sx={{borderRadius: '10px'}}>
                            <CardContent sx={{padding: '3rem'}}>
                                <Typography variant='h5' sx={{fontWeight: 600}}>Loan Calculator</Typography>
                                <Typography variant='body1' mb={3}>Use this calculator to check your loan eligibility</Typography>
                                <TextField sx={{m: '.8rem 0'}} required fullWidth variant='outlined'
                                 value={userInput.firstname} onChange={(e) => dispatch({type: 'FIRST', payload: e.target.value})}
                                 label='First Name' />
                                <TextField sx={{m: '.8rem 0'}} required fullWidth 
                                 value={userInput.lastname} onChange={(e) => dispatch({type: 'LAST', payload: e.target.value})}
                                 variant='outlined' label='Last Name' />
                                <TextField sx={{m: '.8rem 0'}} required type='email' 
                                value={userInput.email} onChange={(e) => dispatch({type: 'EMAIL', payload: e.target.value})}
                                fullWidth variant='outlined' label='Email Address' />
                                <TextField sx={{m: '.8rem 0'}} required fullWidth type={'number'}
                                value={userInput.phone} onChange={(e) => dispatch({type: 'PHONE', payload: e.target.value})}
                                variant='outlined' label='Phone Number' />
                                <Grid container spacing={3}>
                                    <Grid item sm={6}>
                                        <TextField required sx={{m: '.8rem 0'}} type='number' 
                                        value={userInput.salary} onChange={(e) => dispatch({type: 'SALARY', payload: e.target.value})}
                                        inputProps={{ min: 0, step: '1000' }} fullWidth variant='outlined' label='Monthly Salary' InputProps={{
                                            startAdornment: <InputAdornment position='start'>GH¢</InputAdornment>
                                        }} />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Select displayEmpty  required sx={{m: '.8rem 0'}} 
                                        value={userInput.duration} onChange={(e) => dispatch({type: 'DURATION', payload: e.target.value})}
                                        fullWidth variant='outlined' placeholder='Loan Amount'>
                                        {
                                            ['',3,4,5,6,7,8,9,10,11,12].map((el, index) => {
                                                return (
                                                    <MenuItem key={index} value={el}>{el === '' ? 'Select Period' : el + 'months'}</MenuItem>
                                                )
                                            })
                                        }
                                        </Select>
                                    </Grid>
                                </Grid>
                                <LoadingButton size='large' onClick={onFormSubmit} loading={load} sx={{marginTop: '1rem'}} variant='contained' disableElevation color='secondary'> Check Eligibility</LoadingButton>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Card variant='outlined' sx={{borderRadius: '10px', border: theme => `2px solid ${theme.palette.primary.main}`}}>
                            <CardContent sx={{padding: '3rem'}}>
                                <Typography variant='h5' sx={{fontWeight: 600}}>Loan Results</Typography>
                                <Typography mb={2}>You are eligible up to a credit of</Typography>
                                <Typography variant='h3' sx={{fontWeight: 300}}>GH¢ {loan.toLocaleString()}</Typography>
                                 <Divider sx={{my: '1rem'}} />
                                <Typography>Your estimated monthly repayment will be</Typography>
                                <Divider sx={{my: '1rem'}} />
                                <Box mb={1} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography>Interest To Be Applied(PM):</Typography>
                                    <Typography>4% -7%</Typography>
                                </Box>
                                <Box mb={1} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography >Monthly Repayment:</Typography>
                                    <Typography >GH¢ {monthly.toFixed(2).toLocaleString()}</Typography>
                                </Box>
                                {
                                    duration && 
                                    <Box mb={1} sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Typography >Payment Duration:</Typography>
                                        <Typography >{duration} Months</Typography>
                                    </Box>

                                }
                                <Button size='large' sx={{marginTop: '6rem'}} href='https://wepaygh.com' variant='contained' disableElevation color='secondary'>Get Loan Now</Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                
            </Container>
        </Box>

        <Dialog open={open}>
            <DialogContent sx={{padding: '2rem'}}>
                <LinearProgress />
                <Typography mt={1} variant='body1'>Calculating. Please wait...</Typography>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default Home