import { ThemeProvider } from '@mui/material'
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './layout/Home'
import { theme } from './theme'


const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
            <Route path='/' element={<Home />} />
        </Routes>

      </ThemeProvider>
    </>
  )
}

export default App